import React, { useEffect, useState, useContext } from "react";
import ClipboardJS from "clipboard";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import moment from 'moment';

import {
	Avatar,
	IconButton,
	Paper,
	Typography,
	InputLabel,
	Link,
	Drawer,
	Tooltip,
	CardHeader,
	CardActions,
	Collapse,
	Badge
} from "@material-ui/core";

import {
	Person,
	Edit,
	WhatsApp,
	FileCopy,
	Close,
	PlayArrow,
	Assignment,
	PeopleAlt,
	EventNote,
	Email,
	CalendarToday
} from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import ContactModal from "../ContactModal";

import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import MarkdownWrapper from "../MarkdownWrapper";

import { SettingsContext } from "../../context/Settings/SettingsContext";

import ContactDrawerSubscription from "../ContactDrawerSubscription";
import ContactDrawerLink from "../ContactDrawerLink";
import FormatContactNumber from "../FormatContactNumber";

import api from "../../services/api";

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		marginLeft: "5px",
	},
	drawerPaper: {
		width: drawerWidth,
		display: "flex",
		border: "1px solid grey",
		borderRadius: 10,
	},
	header: {
		display: "flex",
		borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		minHeight: "73px",
		justifyContent: "flex-start",
	},
	content: {
		display: "flex",
		flexDirection: "column",
		overflowY: "scroll",
		...theme.scrollbarStyles,
		borderBottom: "1px solid rgba(0, 0, 0, 0.10)",
	},
	contactAvatar: {
		width: 48,
		height: 48,
	},
	actions: {
		marginTop: -5,
		marginLeft: 5
	},
	text: {
		color: theme.palette.textPrimary.main
	},
	contactHeader: {
		backgroundColor: "transparent",
		border: "grey",
		padding: 3,
		paddingTop: 10,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		"& > *": {
			margin: 2,
		},
	},
	contact: {
		marginTop: 8,
		margin: 8,
		padding: 8,
		display: "flex",
		flexDirection: "column",
		borderRadius: 10,
		borderColor: theme.palette.primary.main,
	},
	contactExtraInfo: {
		margin: 6,
		padding: 6,
	},
	copy: {
		marginLeft: 5,
		cursor: "pointer",
	},
	root: {
		marginBottom: 5,
	},
	title: {
		display: "flex",
		fontWeight: 500,
		color: 'black',
		textAlign: 'center',
	},
	pos: {
		marginBottom: 12,
	},

	infoTag: {
		flex: 1,
		display: "flex",
		paddingTop: 3,
		paddingLeft: 5,
		marginRight: 1,
		borderRadius: 3,
	},
	infoTagMany: {
		fontSize: 13,
		borderBottom: "1px solid grey",
		display: "flex",
		flexFlow: "row nowrap",
		justifySelf: "center",
		alignSelf: "center",
	},
	infoTagBlue: {
		color: "RoyalBlue",
	},
	infoTagGreen: {
		color: "#00a859",
	},
	infoButton: {
		paddingRight: 5,
		cursor: "pointer",
	},
	expand: {
		transform: 'rotate(90deg)',
		marginLeft: 'auto',
		fontSize: "50%",
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		fontSize: "50%",
		transform: 'rotate(150deg)',
	},
	badgeCounter: {
		marginLeft: 12,
		top: -5,
	}
}));


const ContactDrawer = ({ open, handleDrawerClose, contact, loading, openTicketonSendAlert }) => {
	const classes = useStyles();
	const { settings } = useContext(SettingsContext);

	const [modalOpen, setModalOpen] = useState(false);
	const [modulesFinancial, setModulesFinancial] = useState();
	const [modulesStreaming, setModulesStreaming] = useState();
	const [launcherBox, setLauncherBox] = useState();
	const [painelTVS, setPainelTVS] = useState();
	const [expanded, setExpanded] = useState(false);
	const [expandedInfo, setExpandedInfo] = useState(false);
	const [expandedLink, setExpandedLink] = useState(false);
	const [subscriptions, setSubscriptions] = useState(false);
	const [contactLinks, setContactLinks] = useState([]);
	const [countDetails, setCountDetails] = useState(0);
	
	const handleExpandClick = (i) => {
		setExpandedInfo(false);
		setExpandedLink(false);
		setExpanded(false);
		if (i === "info") {
			setExpandedInfo(!expandedInfo);
		} else if (i === "link") {
			setExpandedLink(!expandedLink);
		} else {
			setExpanded(!expanded);
		}
	};



	useEffect(() => {
		const getModules = async () => {
			const modulesFinancial = await settings.find(t => t.key === "modulesFinancial");
			setModulesFinancial(modulesFinancial?.value);
			const modulesStreaming = await settings.find(t => t.key === "modulesStreaming");
			setModulesStreaming(modulesStreaming?.value);
			const launcherBox = await settings.find(t => t.key === "launcherBox");
			setLauncherBox(launcherBox?.value);
			const painelTVS = await settings.find(t => t.key === "painelTVS");
			setPainelTVS(painelTVS?.value);
		}
		getModules();


	}, [settings]);

	const fetchSubscriptions = async () => {
		try {
			if (modulesFinancial === "enabled") {
				const { data } = await api.get(`/subscription/listByContactId/${contact.id}`);
				setSubscriptions(data);
			}
		} catch (err) {
			toastError(err);
		}
	}

	useEffect(() => {
		let countDetails = 0;
		countDetails += contact?.extraInfo?.length;
		console.log("contact", contact)
		if (contact?.birthday) countDetails += 1 + contact?.extraInfo?.length;
		if (contact?.email) countDetails += 1;
		setCountDetails(countDetails);




		if (!contact?.id) return;
		const fetchContactsLinks = async () => {
			try {
				const { data } = await api.get(`/contactLinks/list/${contact?.id}`);
				setContactLinks(data);
			} catch (err) {
				toastError(err);
			}
		};
		fetchSubscriptions();
		fetchContactsLinks();
		// eslint-disable-next-line
	}, [contact]);


	const handleSubscriptionsData = () => {
		fetchSubscriptions();
	};


	new ClipboardJS('#copy');

	return (
		<Drawer
			className={classes.drawer}
			variant="persistent"
			anchor="right"
			open={open}
			PaperProps={{ style: { position: "absolute" } }}
			BackdropProps={{ style: { position: "absolute" } }}
			ModalProps={{
				container: document.getElementById("drawer-container"),
				style: { position: "absolute" },
			}}
			classes={{
				paper: classes.drawerPaper,
			}}
		>

			{loading ? (
				<ContactDrawerSkeleton classes={classes} />
			) : (
				<>
					<div>
						<ContactModal
							open={modalOpen}
							onClose={() => setModalOpen(false)}
							contactId={contact.id}
						></ContactModal>

						<CardHeader
							avatar={
								<>
									<Avatar
										alt={contact.name}
										src={contact?.profilePicUrl}
									></Avatar>
									<div className={classes.actions}>
										<IconButton
											size="small"
											onClick={() => setModalOpen(true)}
										>
											<Tooltip title={i18n.t("global.buttons.edit")} >
												<Edit />
											</Tooltip>
										</IconButton>
									</div>
								</>
							}
							title={
								<div className={classes.infoTag}>
									<Person className={`${classes.infoButton} ${classes.infoTagBlue}`} />
									<Typography >{contact?.name}&nbsp; </Typography>
								</div>
							}
							subheader={
								<>
									<div className={classes.infoTag}>
										<WhatsApp className={`${classes.infoButton} ${classes.infoTagGreen}`} />
										<Link className={classes.text} id="contactNumber" href={`tel:${contact.number}`}>
											{FormatContactNumber(contact.number)}
										</Link>
										<FileCopy
											id="copy"
											className={classes.copy}
											data-clipboard-action="copy"
											data-clipboard-target="#contactNumber"
										/>
									</div>
								</>
							}
						/>

						<IconButton onClick={handleDrawerClose} style={{ position: "absolute", top: 5, right: 8 }}>
							<Tooltip title={i18n.t("global.buttons.close")} >
								<Close />
							</Tooltip>
						</IconButton>

					</div>
					<div className={classes.content}>
						<CardActions disableSpacing>
							<EventNote fontSize="small" />
							<Typography variant="body1" color="initial">&nbsp;{i18n.t("contactDrawer.extraInfo")}</Typography>
							<Badge
								overlap="rectangular"
								className={classes.badgeCounter}
								badgeContent={countDetails}
								color="primary"
							/>
							<IconButton
								size="small"
								className={clsx(classes.expand, {
									[classes.expandOpen]: expandedInfo,
								})}
								onClick={() => handleExpandClick("info")}
								aria-expanded={expandedInfo}
								aria-label="show more"

							>
								<PlayArrow fontSize="small" />
							</IconButton>

						</CardActions>
						<Collapse in={expandedInfo} timeout="auto" unmountOnExit>
							<Typography variant="button" display="block" gutterBottom>
								<IconButton
									size="small"
									onClick={() => setModalOpen(true)}
									style={{ top: -40, left: 240 }}
								>
									<Tooltip title={i18n.t("global.buttons.edit")} >
										<Edit fontSize="small" />
									</Tooltip>
								</IconButton>
							</Typography>
							<div style={{ marginTop: -30 }} >
								<Paper variant="outlined" elevation={3} style={{ margin: 5, padding: 5, flex: 1 }}>
									{contact?.email && (
										<div className={classes.infoTag}>
											<Email />&nbsp;<Link id="contactNumber" href={`mailto:${contact.email}`}>{`${contact.email}`}</Link>
										</div>
									)}
									{contact?.birthday && (
										<div className={classes.infoTag}>
											<CalendarToday />&nbsp;{moment(contact.birthday).format('DD/MM/YYYY')}
										</div>
									)}
								</Paper>
							</div>

							{contact?.extraInfo?.map(info => (
								<Paper
									key={info.id}
									square
									variant="outlined"
									className={classes.contactExtraInfo}
								>
									<InputLabel>{info.name}</InputLabel>
									<Typography component="div" noWrap style={{ paddingTop: 2 }}>
										<MarkdownWrapper>{info.value}</MarkdownWrapper>
									</Typography>

								</Paper>
							))}

						</Collapse>
					</div>

					{
						modulesFinancial === "enabled" &&
						<div className={classes.content}>
							<CardActions disableSpacing>
								<Assignment fontSize="small" />
								<Typography variant="body1" color="initial">&nbsp;{i18n.t("subscription.title")}</Typography>
								<Badge
									overlap="rectangular"
									className={classes.badgeCounter}
									badgeContent={subscriptions.length}
									color="primary"
								/>
								<IconButton
									size="small"
									className={clsx(classes.expand, {
										[classes.expandOpen]: expanded,
									})}
									onClick={() => handleExpandClick()}
									aria-expanded={expanded}
									aria-label="show more"
								>
									<PlayArrow fontSize="small" />
								</IconButton>
							</CardActions>
							<Collapse in={expanded} timeout="auto" unmountOnExit>
								<ContactDrawerSubscription handleSubscriptionsData={handleSubscriptionsData} subscriptionsData={subscriptions} openTicketonSendAlert={openTicketonSendAlert} contact={contact} modules={{ modulesFinancial, painelTVS, modulesStreaming, launcherBox }} />
							</Collapse>
						</div>
					}
					<div className={classes.content}>
						<CardActions disableSpacing>
							<PeopleAlt fontSize="small" />
							<Typography variant="body1" color="initial">&nbsp;{i18n.t("contactLink.title")}</Typography>
							<Badge
								overlap="rectangular"
								className={classes.badgeCounter}
								badgeContent={contactLinks.length}
								color="primary"
							/>
							<IconButton
								size="small"
								className={clsx(classes.expand, {
									[classes.expandOpen]: expandedLink,
								})}
								onClick={() => handleExpandClick("link")}
								aria-expanded={expandedLink}
								aria-label="show more"
							>
								<PlayArrow fontSize="small" />
							</IconButton>
						</CardActions>
						<Collapse in={expandedLink} timeout="auto" unmountOnExit>
							<ContactDrawerLink contact={contact} />
						</Collapse>
					</div>
				</>
			)

			}
		</Drawer >
	);
};

export default ContactDrawer;
