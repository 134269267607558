import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import api from "../services/api";
import LoggedInLayout from "../layout";
import Dashboard from "../pages/Dashboard/";
import Reports from "../pages/Reports/";
import Tickets from "../pages/Tickets/";
import Login from "../pages/Login/";
import Connections from "../pages/Connections/";
import Settings from "../pages/Settings/";
import Users from "../pages/Users";
import Contacts from "../pages/Contacts/";
import QuickAnswers from "../pages/QuickAnswers/";
import Queues from "../pages/Queues/";
import Schedules from "../pages/Schedules/";
import Tags from "../pages/Tags/";
import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import { SettingsProvider } from "../context/Settings/SettingsContext";
import { MsServersProvider } from "../context/MsServers/MsServersContext";
import Route from "./Route";
import docs from "../pages/docs/";
import tokens from "../pages/tokens/";
import Super from "../pages/Super/";
import TagsKanban from "../pages/TagsKanban";
import Campaigns from "../pages/Campaigns";
import Plans from "../pages/Plans";
import MsServers from "../pages/MsServers";
import Subscriptions from "../pages/Subscriptions";
import PaymentMethods from "../pages/PaymentMethods";
import Payments from "../pages/Payments";
import PaymentReports from "../pages/PaymentReports";

const Routes = () => {

  const [modulesOnlyCampaigns, setModulesOnlyCampaigns] = useState();

  useEffect(() => {
    const loadsettings = async () => {
      try {
        const { data } = await api.get("/settings/getSettingValue", {
          params: { key: "modulesOnlyCampaigns" },
        });
        setModulesOnlyCampaigns(data);
      } catch (err) {
        return 0;
      }
    }
    loadsettings();
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          <SettingsProvider>
            <MsServersProvider>
              <WhatsAppsProvider>
                <LoggedInLayout>
                  {modulesOnlyCampaigns !== "enabled" && (
                    <>
                      <Route exact path="/" component={Reports} isPrivate />
                      <Route exact path="/tickets/:ticketId?/:spy?" component={Tickets} isPrivate />
                      <Route exact path="/Reports" component={Reports} isPrivate />
                      <Route exact path="/Dashboard" component={Dashboard} isPrivate />
                      <Route exact path="/plans" component={Plans} isPrivate />
                      <Route exact path="/subscriptions" component={Subscriptions} isPrivate />
                      <Route exact path="/quickAnswers" component={QuickAnswers} isPrivate />
                      <Route exact path="/paymentMethods" component={PaymentMethods} isPrivate />
                      <Route exact path="/payments" component={Payments} isPrivate />
                      <Route exact path="/paymentReports" component={PaymentReports} isPrivate />
                    </>
                  )}
                  
                  <Route exact path="/connections" component={Connections} isPrivate />
                  
                  <Route exact path="/contacts" component={Contacts} isPrivate />
                  <Route exact path="/users" component={Users} isPrivate />
                  <Route exact path="/Settings" component={Settings} isPrivate />
                  <Route exact path="/Super" component={Super} isPrivate />
                  <Route exact path="/docs" component={docs} isPrivate />
                  <Route exact path="/tokens" component={tokens} isPrivate />
                  <Route exact path="/Queues" component={Queues} isPrivate />
                  <Route exact path="/tags" component={Tags} isPrivate />
                  <Route exact path="/TagsKanban" component={TagsKanban} isPrivate />
                  <Route exact path="/Campaigns" component={Campaigns} isPrivate />
                  <Route exact path="/schedules" component={Schedules} isPrivate />
                  <Route exact path="/msServers" component={MsServers} isPrivate />

                </LoggedInLayout>
              </WhatsAppsProvider>
            </MsServersProvider>
          </SettingsProvider>
        </Switch>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
