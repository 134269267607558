import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import { green, blue } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import { ListItemText, ListItemIcon, ListItemAvatar, Typography, Avatar, Badge, IconButton } from "@material-ui/core";
import { Person, WhatsApp } from '@material-ui/icons';
import { i18n } from "../../translate/i18n";
import VisibilityIcon from '@material-ui/icons/Visibility';
import DoneIcon from '@material-ui/icons/Done';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import ReplayIcon from '@material-ui/icons/Replay';
import StopIcon from '@material-ui/icons/Stop';
import LocalOfferRoundedIcon from '@material-ui/icons/LocalOfferRounded';
import CustomToolTip from "../ToolTips";

import api from "../../services/api";
import { Can } from "../Can";

import MarkdownWrapper from "../MarkdownWrapper";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import AcceptTicketWithouSelectQueue from "../AcceptTicketWithoutQueueModal";
import ButtonWithSpinner from "../ButtonWithSpinner";

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { SettingsContext } from "../../context/Settings/SettingsContext";

const useStyles = makeStyles(theme => ({
	ticket: {
		margin: 4,
		position: "relative",
		borderRadius: 5,
		right: 8,
		with: "100%",
		paddingTop: 0,
		paddingBottom: 0,
		backgroundColor: theme.palette.card.main
	},

	pendingTicket: {
		cursor: "unset",
	},
	contactNameWrapper: {
		display: "flex",
		justifyContent: "space-between",
	},
	contactName: {
		fontWeight: "500",
	},
	lastMessageTime: {
		justifySelf: "flex-end",
		fontSize: "90%",
	},
	closedBadge: {
		alignSelf: "center",
		justifySelf: "center",
		marginRight: 42,
		paddingRight: 20,
		top: "-2px",
		marginLeft: "auto",
	},

	contactLastMessage: {
		paddingRight: 20,
	},

	newMessagesCount: {
		alignSelf: "center",
		marginRight: 8,
		marginLeft: "auto",
	},
	bottons: {
		marginRight: -10,
		display: "flex",
		alignItems: "right",
		justifyContent: "flex-end",
	},
	bottomIcon: {
		padding: 1,
		fontSize: "135%",
		marginRight: 3,
	},
	badgeStyle: {
		color: "white",
		backgroundColor: green[500],
	},

	acceptButton: {
		padding: 1,
		paddingRight: 3,
		marginRight: 3,
		fontSize: "75%",
		// position: "absolute",
		// left: "50%",
	},
	spyButton: {
		backgroundColor: blue[400],
	},
	assignMeButton: {
		backgroundColor: green[400],
		padding: 1,
		paddingRight: 3,
		marginRight: 3,
		fontSize: "75%",
		// position: "absolute",
		// left: "50%",
	},

	ticketQueueColor: {
		flex: "none",
		width: "6px",
		height: "100%",
		position: "absolute",
		borderTopLeftRadius: 5,
		borderBottomLeftRadius: 5,
		top: "0%",
		left: "0%",
	},

	infoTags: {
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "right",
		position: "absolute",
		bottom: 3,
		right: 3,
		fontSize: "0.9em"
	},
	infoTagsData: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "left",
		bottom: 0,
		right: 3,
		fontSize: "0.9em",
	},
	infoTag: {
		cursor: "pointer",
		display: "flex",
		color: "gray",
		paddingTop: 2,
		paddingRight: 5,
		marginRight: 1,
		borderRadius: 3,
	},
	infoTagRed: {
		fontWeight: "600",
		background: theme.palette.secondary.main,
		border: "1px solid " + theme.palette.secondary.main,
		color: "#FFF",
		fontSize: "85%",
		padding: 1,
		paddingTop: 2,
		paddingLeft: 6,
		paddingRight: 6,
		borderRadius: 15,
	},
	infoTagBlue: {
		color: "RoyalBlue",
	},
	infoTagGreen: {
		color: "#00a859",
	},
	infoButton: {
		padding: 1,
		height: 15,
		fontSize: "115%",
		cursor: "pointer",
	},

}));


const TicketListItem = ({ onSetTabOpen, ticket }) => {
	const classes = useStyles();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { ticketId } = useParams();
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);
	const [acceptTicketWithouSelectQueueOpen, setAcceptTicketWithouSelectQueueOpen] = useState(false);
	const [contextMenu, setContextMenu] = useState(null);
	const { settings } = useContext(SettingsContext);
	const [ticketWaitingNoShowMessagem, setTicketWaitingNoShowMessagem] = useState("disabled");
	const [userSpyTicket, setUserSpyTicket] = useState("disabled");

	useEffect(() => {
		const loadsettings = async () => {
			const ticketWaitingNoShowMessagem = await settings.find(t => t.key === "ticketWaitingNoShowMessagem");
			setTicketWaitingNoShowMessagem(ticketWaitingNoShowMessagem?.value);
			const userSpyTicket = await settings.find(t => t.key === "userSpyTicket");
			setUserSpyTicket(userSpyTicket?.value);
		}
		loadsettings();
	}, [settings]);

	const handleContextMenu = (event) => {
		event.preventDefault();
		setContextMenu(
			contextMenu === null
				? {
					mouseX: event.clientX + 2,
					mouseY: event.clientY - 6,
				}
				:
				null,
		);
	};

	const handleClose = () => {
		setContextMenu(null);
	};


	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleAcepptTicket = async id => {
		setLoading(true);
		try {
			await api.put(`/tickets/${id}`, {
				status: "open",
				userId: user?.id,
			});
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if (isMounted.current) {
			onSetTabOpen("open");
			setLoading(false);
		}
		await history.push(`/tickets/${id}`);
	};

	const handleAssignMeTicket = async id => {
		setLoading(true);
		try {
			await api.put(`/tickets/${id}`, {
				userId: user?.id,
				status: "pending",
			});
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if (isMounted.current) {
			setLoading(false);
		}
	};


	const handleReopenTicket = async id => {
		setLoading(true);
		try {
			await api.put(`/tickets/${id}`, {
				status: "open",
				userId: user?.id,
			});
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if (isMounted.current) {
			onSetTabOpen("open");
			setLoading(false);
		}
		history.push(`/tickets/${id}`);
	};

	const handleViewTicket = async id => {
		setLoading(true);
		try {
			await api.put(`/tickets/${id}`, {
				status: "pending",
				userId: user?.id,
			});
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if (isMounted.current) {
			setLoading(false);
		}
		history.push(`/tickets/${id}`);
	};

	const handleSpyTicket = async id => {
		setLoading(true);
		
		try {
			await api.get(`/tickets/${id}`);
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if (isMounted.current) {
			setLoading(false);
		}
		
		history.push(`/tickets/${id}/true`); 

	};

	const handleClosedTicket = async id => {
		setLoading(true);
		try {
			await api.put(`/tickets/${id}`, {
				status: "closed",
				userId: user?.id,
			});
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if (isMounted.current) {
			setLoading(false);
		}
		history.push(`/tickets/${id}`);
	};


	const handleSelectTicket = id => {
		history.push(`/tickets/${id}`);
	};

	const queueName = selectedTicket => {
		let name = null;
		let color = null;
		user.queues.forEach(userQueue => {
			if (userQueue.id === selectedTicket.queueId) {
				name = userQueue.name;
				color = userQueue.color;
			}
		});
		return {
			name,
			color
		};
	}

	const handleOpenAcceptTicketWithouSelectQueue = () => {
		setAcceptTicketWithouSelectQueueOpen(true);
	};

	function TagList(props) {
		const tags = props;
		if (tags.tags.length < 1) return (<></>)

		const listItems = tags.tags.map((tag) =>
			<CustomToolTip
				title={tag.name}
				key={tag.id}
			>
				<LocalOfferRoundedIcon fontSize="small" style={{ color: tag.color }} />
			</CustomToolTip>
		);
		return (
			listItems
		);
	}

	return (
		<React.Fragment key={ticket.id} >
			 <Menu
				open={contextMenu !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					contextMenu !== null
						? { top: contextMenu.mouseY, left: contextMenu.mouseX }
						: undefined
				}
			>
				{/* {(ticket.status === "pending" && ticket.queue !== null) && (

					<MenuItem onClick={e => handleAcepptTicket(ticket.id)} >
						<ListItemIcon>
							<DoneIcon />
						</ListItemIcon>
						<ListItemText>{i18n.t("ticketsList.buttons.accept")}</ListItemText>
					</MenuItem>
				)}
				{(ticket.status === "pending" && (ticket.queue === null || ticket.queue === undefined)) && (
					<MenuItem onClick={e => handleOpenAcceptTicketWithouSelectQueue()} >
						<ListItemIcon>
							<DoneIcon />
						</ListItemIcon>
						<ListItemText>{i18n.t("ticketsList.buttons.acceptBeforeBot")}</ListItemText>
					</MenuItem>

				)} */}
				{
				// (ticket.status === "pending" && user.profile.toUpperCase() === "SUPER") || (userSpyTicket === "enabled" && ticket.status === "pending") ? (
				(user.profile.toUpperCase() === "SUPER" || user.profile.toUpperCase() === "ADMIN") || (userSpyTicket === "enabled") ? (

					<MenuItem onClick={e => handleSpyTicket(ticket.id)} >
						<ListItemIcon>
							<VisibilityIcon />
						</ListItemIcon>
						<ListItemText>{i18n.t("ticketsList.buttons.spy")}</ListItemText>
					</MenuItem>
				) : null}

				{ticket.status === "open" && (
					<Can
						role={user.profile}
						perform="drawer-items:management"
						yes={() => (
							<MenuItem onClick={e => handleViewTicket(ticket.id)} >
								<ListItemIcon>
									<ReplayIcon />
								</ListItemIcon>
								<ListItemText>{i18n.t("messagesList.header.buttons.return")}</ListItemText>
							</MenuItem>
						)}
					/>
				)}
				{ticket.status === "open" && (
					<MenuItem onClick={e => handleClosedTicket(ticket.id)} >
						<ListItemIcon>
							<StopIcon />
						</ListItemIcon>
						<ListItemText>{i18n.t("messagesList.header.buttons.resolve")}</ListItemText>
					</MenuItem>
				)}
				{/* {ticket.status === "closed" && (
					<MenuItem onClick={e => handleReopenTicket(ticket.id)} >
						<ListItemIcon>
							<ReplayIcon />
						</ListItemIcon>
						<ListItemText>{i18n.t("messagesList.header.buttons.reopen")}</ListItemText>
					</MenuItem>
				)} */}
			</Menu>
			<AcceptTicketWithouSelectQueue
				modalOpen={acceptTicketWithouSelectQueueOpen}
				onClose={(e) => setAcceptTicketWithouSelectQueueOpen(false)}
				ticketId={ticket.id}
			/>

			<ListItem
				style={{ 
					boxShadow: "0 4px 6px -4px"+ ticket.queue?.color || queueName(ticket)?.color || "rgba(0, 0, 0, 0.12)",
					// border: ticket.queue?.color ? "1px solid "+ticket.queue?.color : "1px solid rgba(0, 0, 0, 0.12)",
					// backgroundColor: ticket.queue?.color ? ticket.queue?.color : "rgba(0, 0, 0, 0.12)"
				}}
				onContextMenu={handleContextMenu}
				onMouseOver={handleClose}
				dense
				button
				onClick={e => {
					setContextMenu(null);
					if (ticket.status === "pending") return;
					handleSelectTicket(ticket.id);
				}}
				selected={ticketId && +ticketId === ticket.id}
				className={clsx(classes.ticket, {
					[classes.pendingTicket]: ticket.status === "pending"
				})}
			>
				<CustomToolTip
					title={ticket.queue?.name || queueName(ticket)?.name || "Sem fila"}
				>
					<span
						style={{ backgroundColor: ticket.queue?.color || queueName(ticket)?.color || "rgba(0, 0, 0, 0.12)" }}
						className={classes.ticketQueueColor}
					></span>
				</CustomToolTip>

				<ListItemAvatar>
					<Avatar src={ticket?.contact?.profilePicUrl} />
				</ListItemAvatar>
				<ListItemText
					disableTypography
					primary={
						<span className={classes.contactNameWrapper}>
							<Typography
								className={classes.contactName}
								noWrap
								component="span"
								variant="body2"
								color="textPrimary"
							>
								{ticket.contact?.name}
							</Typography>
							{ticket.status === "closed" && (
								<Badge
									overlap="rectangular"
									className={classes.closedBadge}
									badgeContent={"Encerrada"}
									color="primary"
								/>
							)}
							{ticket.lastMessage && (
								<Typography
									className={classes.lastMessageTime}
									component="span"
									variant="body2"
									color="textSecondary"
								>
									{isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
										<>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
									) : (
										<>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
									)}
								</Typography>
							)}

						</span>
					}
					secondary={
						<div style={{ display: "flex", flexDirection: "column" }}>
							<span className={classes.contactNameWrapper}>
								<Typography
									className={classes.contactLastMessage}
									noWrap
									component="span"
									variant="body2"
									color="textSecondary"
								>
									{ticketWaitingNoShowMessagem === "enabled" && ticket.status === "pending" && (user.profile !== "admin" && user.profile !== "super") ? (
										<Skeleton animation="wave" width={280} />
									) : (

										ticket.lastMessage ? (
											<MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
										) : (
											<Skeleton animation="wave" width={280} />
										)

									)}
									<div className={classes.infoTagsData}>
										{ticket.whatsappId && (
											<CustomToolTip title={i18n.t("ticketsList.connectionTitle")} >
												<div className={classes.infoTag}>
													<WhatsApp className={`${classes.infoButton} ${classes.infoTagGreen}`} />
													{ticket.whatsapp?.name}
												</div>
											</CustomToolTip>
										)}
										{ticket.userId && (
											<CustomToolTip title={i18n.t("ticketsList.userTitle")} >
												<div className={classes.infoTag}>
													<Person className={`${classes.infoButton} ${classes.infoTagBlue}`} />
													{ticket.user?.name}
												</div>
											</CustomToolTip>
										)}
										{ticket.contact.tags && (
											<TagList tags={ticket.contact.tags} />
										)}
									</div>
								</Typography>


							</span>

							<span className={classes.bottons}>
								{(ticket.status === "pending" && ticket.queue !== null && !ticket.userId) && (
									<ButtonWithSpinner
										color="primary"
										variant="contained"
										className={classes.assignMeButton}
										size="small"
										loading={loading}
										onClick={e => handleAssignMeTicket(ticket.id)}
									>
										<PersonPinIcon className={classes.bottomIcon} /> {i18n.t("ticketsList.buttons.assignMe")}
									</ButtonWithSpinner>
								)}
								{(ticket.status === "pending" && ticket.queue !== null) && (
									<ButtonWithSpinner
										color="primary"
										variant="contained"
										className={classes.acceptButton}
										size="small"
										loading={loading}
										onClick={e => handleAcepptTicket(ticket.id)}
									>
										<DoneIcon className={classes.bottomIcon} /> {i18n.t("ticketsList.buttons.accept")}
									</ButtonWithSpinner>
								)}
								{(ticket.status === "pending" && (ticket.queue === null || ticket.queue === undefined)) && (
									<ButtonWithSpinner
										color="secondary"
										variant="contained"
										className={classes.acceptButton}
										size="small"
										loading={loading}
										onClick={e => handleOpenAcceptTicketWithouSelectQueue()}
									>
										<DoneIcon className={classes.bottomIcon} /> {i18n.t("ticketsList.buttons.acceptBeforeBot")}
									</ButtonWithSpinner>
								)}

								{ticket.status === "closed" && (

									<ButtonWithSpinner
										color="primary"
										variant="contained"
										className={classes.acceptButton}
										size="small"
										loading={loading}
										onClick={e => handleReopenTicket(ticket.id)}
									>
										<ReplayIcon className={classes.bottomIcon} /> {i18n.t("messagesList.header.buttons.reopen")}
									</ButtonWithSpinner>
								)}
								{ticket.status === "closed" && (
									<IconButton
										className={classes.bottomButton}
										color="primary" >
									</IconButton>
								)}
								{
								
								// (ticket.status === "pending" && user.profile.toUpperCase() === "SUPER") || (userSpyTicket === "enabled" && ticket.status === "pending") ? (
									(user.profile.toUpperCase() === "SUPER" || user.profile.toUpperCase() === "ADMIN") || (userSpyTicket === "enabled") ? (
									<ButtonWithSpinner
										color="primary"
										variant="contained"
										className={`${classes.acceptButton} ${classes.spyButton}`}
										size="small"
										loading={loading}
										onClick={e => handleSpyTicket(ticket.id)}
										title={i18n.t("ticketsList.buttons.spy")}
									>
										<VisibilityIcon className={classes.bottomIcon} /> {i18n.t("ticketsList.buttons.spy")}
									</ButtonWithSpinner>

								) : null}

								{ticket.unreadMessages !== 0 && (
									<div className={`${classes.infoTag} ${classes.infoTagRed}`} >
										{ticket.unreadMessages}</div>
								)}

							</span>

						</div>
					}
				/>


			</ListItem>
			{/* <Divider variant="inset" component="li" /> */}
		</React.Fragment>
	);
};

export default TicketListItem;
