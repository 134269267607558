import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import clsx from "clsx";
import PropTypes from 'prop-types';

import PriceBRL from "../PriceBRL";

import {
  Close
} from "@material-ui/icons";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
  TextField,
  FormControl,
  FormControlLabel,
  Switch,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Paper,
  Checkbox,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableHead,
  Toolbar,
  Typography,
  IconButton,
  Badge,
  Collapse
} from "@material-ui/core";

import { Alert, AlertTitle } from "@material-ui/lab";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import { isArray } from "lodash";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", flexWrap: "wrap", },

  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  infoTag: {
    flex: 1,
    display: "flex",
    paddingTop: 3,
    paddingLeft: 5,
    marginRight: 1,
    borderRadius: 3,

  },
  infoTagMany: {
    fontSize: 13,
    display: "flex",
    flexFlow: "row nowrap",
    justifySelf: "center",
    alignSelf: "center",
  },
  infoTagExpire72: {
    color: "#FEDE00",
  },
  infoTagExpire24: {
    color: "#FF8C00",
  },
  infoTagExpired: {
    color: theme.palette.secondary.main,
  },
  infoTagBlue: {
    color: "RoyalBlue",
  },
  infoTagGreen: {
    color: "#00a859",
  },
  infoButton: {
    paddingRight: 5,
    cursor: "pointer",
  },
  badgeCounter: {
    marginLeft: 12,
    top: -5,
  },
  tableContainer: {
    maxHeight: 95,
  },
}));


const PaymentModal = ({ modalOpen, onClose, contactId, modules, subscriptionsData, msServers, paymentId }) => {
  const classes = useStyles();

  const initialState = {
    paymentId: "",
    paymentMethodId: "",
    value: 0,
    description: "",
    payDate: moment().format('YYYY-MM-DDTHH:mm'),
    status: true,
  };

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [payment, setPayment] = useState(initialState);

  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openAlert, setOpenAlert] = useState(false);
  const [listLast, setListLast] = useState([]);
  const [valueData, setValueData] = useState(0);


  const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
      <Toolbar>
        <Typography variant="h6" id="tableTitle" component="div">
          {i18n.t("payment.subTitle")}
          <Badge
            overlap="rectangular"
            className={classes.badgeCounter}
            badgeContent={numSelected}
            color="primary"
          />
        </Typography>  <span style={{ marginLeft: 20 }} > ({i18n.t("payment.subTitleText")})</span>
      </Toolbar>
    );
  };


  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const expireHours = (a) => {
    const b = moment(a).diff(moment(), 'hours')
    return b;
  }

  const SessionSchema = Yup.object().shape({
    paymentMethodId: Yup.string().required(i18n.t("validation.required")),
    payDate: Yup.string().required(i18n.t("validation.required")),

  });

  const msServerName = (msServerId) => {
    const msServersFilter = msServers?.filter(function (e) { return e.id === msServerId; })
    const msServersName = msServersFilter.length > 0 ? msServersFilter[0].name : "";
    return msServersName;
  }

  const changeValue = async (e) => {
    let valueString = `${e}`;
    valueString = valueString.replace(/\D/g, "");
    var tmp = valueString.replace(/([0-9]{2})$/g, ",$1");
    setValueData(tmp);
  };

  const changeValueInitialNoEdit = async (e) => {
    let valueString = `${e}`;
    valueString = valueString.replace(/\D/g, "");
    var tmp = valueString.replace(/([0-9]{2})$/g, ",$1");
    setPayment({ ...payment, 'value': tmp });
  };

  const changeValueInitial = async (e) => {
    let valueString = `${e.value}`;
    const valueDate = new Date(e.payDate);
    const newDate = moment(valueDate).format('YYYY-MM-DDTHH:mm');

    valueString = valueString.replace(/\D/g, "");
    var tmp = valueString.replace(/([0-9]{2})$/g, ",$1");
    setPayment({ ...e, 'value': tmp, 'payDate': newDate });
  };

  useEffect(() => {
    if (!contactId) return;
    try {
      (async () => {
        const { data } = await api.get('/paymentMethod/list');
        if (isArray(data)) {
          setPaymentMethods(data);
        }
      })()
    } catch (err) {
      toastError(err);
    }
  }, [modalOpen, contactId]);

  useEffect(() => {
    if (paymentId) return;
    if (!contactId) return;
    try {
      (async () => {
        const { data } = await api.get(`/payment/listlast/${contactId}`);
        if (isArray(data)) {
          setListLast(data)
          if (data.length > 0) {
            setOpenAlert(true)
          }
        }
      })()
    } catch (err) {
      toastError(err);
    }

  }, [modalOpen, contactId, paymentId]);

  useEffect(() => {
    if (!paymentId) return;
    const fetchSession = async () => {
      try {
        const { data } = await api.get(`payment/${paymentId}`);
        setPayment(data);
        changeValueInitial(data);
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
    // eslint-disable-next-line
  }, [modalOpen, paymentId]);

  useEffect(() => {
    if (!paymentId) return;
    const fetchSession = async () => {
      try {
        const { data } = await api.get(`subscriptionPayment/listByPaymentId/${paymentId}`);
        const newSelecteds = data.map((n) => n.subscriptionId);
        setSelected(newSelecteds);
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
    // eslint-disable-next-line
  }, [modalOpen, paymentId]);


  useEffect(() => {
    if (paymentId) return;
    let valuePlanSun = 0;
    subscriptionsData.map(async item => {
      valuePlanSun = valuePlanSun + item?.plan?.price;
    })
    changeValueInitialNoEdit(parseFloat(valuePlanSun).toFixed(2))
    const newSelecteds = subscriptionsData.map((n) => n.id);
    setSelected(newSelecteds);
    // eslint-disable-next-line
  }, [modalOpen, subscriptionsData, paymentId]);

  useEffect(() => {
    async function fetchData() {
      let valuePlanSun = 0;
      let promises = selected.map(async item => {
        const idItem = await subscriptionsData.filter(function (e) { return e.id === item; })
        valuePlanSun = valuePlanSun + parseFloat(idItem[0]?.plan?.price);
      })
      Promise.all(promises).then(async function () {
        changeValue(parseFloat(valuePlanSun).toFixed(2));

      })
    };
    fetchData();
    // eslint-disable-next-line
  }, [modalOpen, selected, subscriptionsData]);

  const handleValueOnChange = (e) => {
    let valueString = `${e.target.value}`;
    valueString = valueString.replace(/\D/g, "");
    var tmp = valueString.replace(/([0-9]{2})$/g, ",$1");

    setValueData(tmp);
    return tmp;

  };

  const handleSave = async (values) => {
    // const valuesData = { ...values, subscriptionsPayments: selected};
    const valuesData = { ...values, subscriptionsPayments: selected, contactId, "value": valueData };
    try {
      if (paymentId) {
        await api.put(`/payment/${values.id}`, valuesData);
      } else {
        await api.post("/payment", valuesData);
      }
      toast.success(i18n.t("global.successSave"));
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = subscriptionsData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClose = () => {
    onClose();
    setPayment(initialState);
    setOpenAlert(false)
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, subscriptionsData.length - page * rowsPerPage);


  const headCells = [
    { id: 'ID', numeric: false, disablePadding: true, label: 'ID' },
    { id: 'expiresAt', numeric: false, disablePadding: false, label: 'Vencimento' },
    { id: 'value', numeric: false, disablePadding: false, label: 'Valor' },
  ];


  function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={modalOpen}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          {paymentId
            ? `${i18n.t("global.buttons.edit")} ${i18n.t("payment.name")}`
            : `${i18n.t("global.buttons.add")} ${i18n.t("payment.name")}`
          }
        </DialogTitle>
        {!paymentId &&
          <div>
            <Collapse in={openAlert}  >
              <Paper elevation={3} style={{ margin: 5 }} >
                <Alert
                  variant="filled"
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"

                      color="inherit"
                      size="small"
                      onClick={() => { setOpenAlert(false) }}
                    >
                      <Close fontSize="inherit" />
                    </IconButton>
                  }
                >
                  <AlertTitle><strong>{i18n.t("payment.alert.title")}:</strong></AlertTitle>
                  {i18n.t("payment.alert.text")}
                </Alert>

                <TableContainer style={{ margin: 5 }} className={classes.tableContainer}>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={'small'}
                    aria-label="enhanced table"
                  >
                    <TableBody>
                      {listLast?.map((subs, index) => {
                        return (
                          <TableRow
                            hover
                            key={subs.id}
                          >
                            <TableCell component="th" scope="row" padding="none">
                              {subs.payDate ? moment(subs.payDate).format('DD/MM/YY HH:mm') : ""}
                            </TableCell>
                            <TableCell align="right">
                              <strong>{<PriceBRL value={subs.value} />}</strong>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Collapse>
          </div>
        }
        <Formik
          initialValues={payment}
          enableReinitialize={true}
          validationSchema={SessionSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSave(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ setFieldValue, values, touched, errors, isSubmitting }) => (

            <Form>
              <DialogContent dividers>
                <div>
                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <InputLabel>{i18n.t("global.paymentMethod")}s</InputLabel>
                    <Field
                      as={Select}
                      name="paymentMethodId"
                      color="primary"
                      label={i18n.t("global.paymentMethod")}
                      error={touched.paymentMethodId && Boolean(errors.paymentMethodId)}
                      helpertext={touched.paymentMethodId && errors.paymentMethodId}
                    >
                      {paymentMethods.map((paymentMethod) => (
                        <MenuItem key={paymentMethod.id} value={paymentMethod.id}>{paymentMethod.name}</MenuItem>
                      ))}

                    </Field>
                  </FormControl>
                  <FormControl>
                    <Field
                      as={TextField}
                      label={i18n.t("global.value")}
                      fullWidth
                      name="value"
                      value={valueData}
                      error={
                        touched.value && Boolean(errors.value)
                      }
                      helperText={
                        touched.value && errors.value
                      }
                      variant="outlined"
                      margin="dense"
                      onChange={(value) => {
                        setFieldValue("value", handleValueOnChange(value));
                      }}
                      InputProps={{ startAdornment: <InputAdornment position="start">R$</InputAdornment> }}
                    />
                  </FormControl>
                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <Field
                      type="datetime-local"
                      as={TextField}
                      name="payDate"
                      variant="outlined"
                      margin="dense"
                      label={i18n.t("global.payDate")}
                      error={touched.payDate && Boolean(errors.payDate)}
                      helperText={touched.payDate && errors.payDate}
                    />
                  </FormControl>

                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <Field
                      as={TextField}
                      label={i18n.t("global.description")}
                      multiline
                      minRows={3}
                      name="description"
                      variant="outlined"
                      margin="dense"
                    />
                  </FormControl>
                </div>
                <Paper variant="outlined" elevation={3} >
                  <EnhancedTableToolbar numSelected={selected.length} />
                  <TableContainer>
                    <Table
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      size={'small'}
                      aria-label="enhanced table"
                    >
                      <EnhancedTableHead
                        classes={classes}
                        numSelected={selected.length}
                        onSelectAllClick={handleSelectAllClick}
                        rowCount={subscriptionsData.length}
                      />
                      <TableBody>
                        {subscriptionsData.map((subs, index) => {
                          const isItemSelected = isSelected(subs.id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={(event) => handleClick(event, subs.id)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={subs.id}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              </TableCell>
                              <TableCell component="th" id={labelId} scope="row" padding="none">
                                {`#${subs.id}`} {modules.modulesStreaming === "enabled" && msServers &&
                                  msServerName(subs.msServerId)
                                }
                                {modules.modulesStreaming !== "enabled" &&
                                  `#ID ${subs.id}`
                                }
                              </TableCell>
                              <TableCell align="right">


                                <span
                                  className={clsx(`${classes.infoTagMany}`, {
                                    [classes.infoTagGreen]: expireHours(subs.expiresAt) > 120,
                                    [classes.infoTagExpire72]: expireHours(subs.expiresAt) <= 120,
                                    [classes.infoTagExpire24]: expireHours(subs.expiresAt) <= 24,
                                    [classes.infoTagExpired]: expireHours(subs.expiresAt) <= 0,
                                  })}
                                >
                                  {subs.expiresAt ? moment(subs.expiresAt).format('DD/MM/YY HH:mm') : ""}
                                </span>

                              </TableCell>
                              <TableCell align="right">
                                <span className={classes.infoTagMany}>
                                  <PriceBRL value={subs.plan.price} /> ({subs.plan.name})
                                </span>

                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 10 * emptyRows }}>
                            <TableCell colSpan={4} >
                              {subscriptionsData.length < 1 &&
                                "Nenhum registro encontrado!"
                              }</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={subscriptionsData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </DialogContent>
              <DialogActions>
                <div className={classes.multFieldLine} >
                  <FormControlLabel
                    control={
                      <Field
                        as={Switch}
                        color="primary"
                        name="status"
                        checked={values.status}
                      />
                    }
                    label="Status"
                  />
                </div>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("global.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {paymentId
                    ? i18n.t("global.buttons.okEdit")
                    : i18n.t("global.buttons.okAdd")}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>


            </Form>
          )}
        </Formik>




      </Dialog>
    </div>
  );
};

export default React.memo(PaymentModal);
