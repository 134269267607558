import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
  TextField,
  FormControl,
} from "@material-ui/core";


import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));



const LauncherBoxModal = ({ open, onClose, launcherBoxId, subscriptionId }) => {
  const classes = useStyles();

  const initialState = {
    sLauncherBox: "",
    sLBDueDate: moment().format('YYYY-MM-DDTHH:mm'),
    sLBValidityCredit: moment().format('YYYY-MM-DDTHH:mm'),
  };
 
  const [launcherBox, setLauncherBox] = useState(initialState);

  const SessionSchema = Yup.object().shape({

    sLauncherBox: Yup.string().required(i18n.t("validation.required")),
    sLBDueDate: Yup.string().required(i18n.t("validation.required")),
    sLBValidityCredit: Yup.string().required(i18n.t("validation.required")),

  });


  const changeDate = async (e) => {
    const valueString = new Date(e.sLBDueDate);
    const newValue = moment(valueString).format('YYYY-MM-DDTHH:mm');
    
    const sLBValidityCredit = new Date(e.sLBValidityCredit);
    const sLBValidityCreditD = moment(sLBValidityCredit).format('YYYY-MM-DDTHH:mm');

    setLauncherBox({ ...e, 'sLBDueDate': newValue, 'sLBValidityCredit': sLBValidityCreditD });
  };

  useEffect(() => {
    const fetchSession = async () => {
      if (!launcherBoxId) return;

      try {
        const { data } = await api.get(`launcherBox/${launcherBoxId}`);
        // eslint-disable-next-line
        changeDate(data);

      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
    // eslint-disable-next-line
  }, [launcherBoxId]);

  const handleSaveLauncherBox = async (values) => {

    let launcherBoxData = { ...values, 'subscriptionId': subscriptionId }

    try {
      if (launcherBoxId) {
        await api.put(`/launcherBox/${values.id}`, launcherBoxData);
      } else {
        await api.post("/launcherBox", launcherBoxData);
      }
      toast.success(i18n.t("global.successSave"));
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleClose = () => {
    onClose();
    setLauncherBox(initialState);
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          {launcherBoxId
            ? `${i18n.t("global.buttons.edit")} LauncherBox`
            : `${i18n.t("global.buttons.add")} LauncherBox`
          }
        </DialogTitle>
        <Formik
          initialValues={launcherBox}
          enableReinitialize={true}
          validationSchema={SessionSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveLauncherBox(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting }) => (

            <Form>
              <DialogContent dividers>
                <FormControl variant="outlined" margin="dense" fullWidth>
                  <Field
                    as={TextField}
                    autoFocus
                    name="sLauncherBox"
                    id="sLauncherBox"
                    variant="outlined"
                    margin="dense"
                    label={i18n.t("launcherBox.cod")}
                    error={touched.sLauncherBox && Boolean(errors.sLauncherBox)}
                    helpertext={touched.sLauncherBox && errors.sLauncherBox}
                  />
                  </FormControl>
                  </DialogContent>
                  <DialogContent dividers>
                  <FormControl variant="outlined" margin="dense" fullWidth>
                  
                  <Field
                    type="datetime-local"
                    as={TextField}
                    name="sLBDueDate"
                    id="sLBDueDate"
                    variant="outlined"
                    margin="dense"
                    label={i18n.t("global.expiresAt")}
                    error={touched.sLBDueDate && Boolean(errors.sLBDueDate)}
                    helperText={touched.sLBDueDate && errors.sLBDueDate}
                  />

                </FormControl>
                <FormControl variant="outlined" margin="dense" fullWidth>
                  
                  <Field
                    type="datetime-local"
                    as={TextField}
                    name="sLBValidityCredit"
                    id="sLBValidityCredit"
                    variant="outlined"
                    margin="dense"
                    label={i18n.t("global.creditValidity")}
                    error={touched.sLBValidityCredit && Boolean(errors.sLBValidityCredit)}
                    helperText={touched.sLBValidityCredit && errors.sLBValidityCredit}
                  />

                </FormControl>                
              </DialogContent>
              <DialogActions>

                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("global.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {subscriptionId
                    ? i18n.t("global.buttons.okEdit")
                    : i18n.t("global.buttons.okAdd")}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default React.memo(LauncherBoxModal);
