import React, { useState, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Typography, IconButton, Paper, Tooltip, useTheme } from "@material-ui/core";

import {
	DeleteOutline,
	CalendarToday,
	Person,
	LockOpen,
	MonetizationOn,
	Sync,
	Edit,
	AddCircle,
	AddAlert
} from "@material-ui/icons";

import moment from 'moment';

import { i18n } from "../../translate/i18n";
import { MsServersContext } from "../../context/MsServers/MsServersContext";

import api from "../../services/api";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import clsx from "clsx";

import PriceBRL from "../PriceBRL";
import SubscriptionModal from "../SubscriptionModal";
import ConfirmationModal from "../ConfirmationModal";
import ContactDrawerLauncherBoxCards from "../ContactDrawerLauncherBoxCards";
import TableRowSkeleton from "../TableRowSkeleton";
import SendAlertNotification from "../SendAlertNotification";
import PaymentModal from "../PaymentModal";

const useStyles = makeStyles(theme => ({

	contactDetails: {
		margin: 8,
		padding: 8,
		display: "flex",
		flexDirection: "column",
		borderRadius: 10,
		borderColor: theme.palette.primary.main,
	},

	copy: {
		marginLeft: 5,
		cursor: "pointer",
	},
	root: {
		marginBottom: 5,
	},
	title: {
		display: "flex",
		fontWeight: 500,
		textAlign: 'center',
	},
	pos: {
		marginBottom: 12,
	},

	infoTag: {
		flex: 1,
		display: "flex",
		paddingTop: 3,
		paddingLeft: 5,
		marginRight: 1,
		borderRadius: 3,

	},
	infoTagMany: {
		fontSize: 13,
		display: "flex",
		flexFlow: "row nowrap",
		justifySelf: "center",
		alignSelf: "center",
	},
	infoTagExpire72: {
		color: "#FEDE00",
	},
	infoTagExpire24: {
		color: "#FF8C00",
	},
	infoTagExpired: {
		color: theme.palette.secondary.main,
	},
	infoTagBlue: {
		color: "RoyalBlue",
	},
	infoTagGreen: {
		color: "#00a859",
	},
	infoButton: {
		paddingRight: 5,
		cursor: "pointer",
	}
}));


const ContactDrawerSubscription = ({ contact, modules, openTicketonSendAlert, handleSubscriptionsData, subscriptionsData }) => {
	const classes = useStyles();
	const theme = useTheme();

	const { msServers } = useContext(MsServersContext);
	const [deletingSubscriptions, setDeletingSubscriptions] = useState(null);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [selectedSubscriptions, setSelectedSubscriptions] = useState(null);
	const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
	const [selectedContact, setSelectedContact] = useState(null);
	const [sendAlertModal, setSendAlertModal] = useState(false);
	const [paymentModal, setPaymentModal] = useState(false);
	const [extendSubscriptions, setExtendSubscriptions] = useState(null);
	const [confirmExtendModalOpen, setConfirmExtendModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const expireHours = (a) => {
		const b = moment(a).diff(moment(), 'hours')
		return b;
	}

	const msServerName = (msServerId) => {
		const msServersFilter = msServers?.filter(function (e) { return e.id === msServerId; })
		const msServersName = msServersFilter.length > 0 ? msServersFilter[0].name : "";
		return msServersName;
	}


	const handleOpenSubscriptionModal = (contact) => {
		setSelectedContact(contact)
		setSelectedSubscriptions(null);
		setSubscriptionModalOpen(true);
	};

	const handleCloseSubscriptionModal = async () => {

		await handleSubscriptionsData();

		setSelectedSubscriptions(null);
		setSubscriptionModalOpen(false);
	};

	const handleEditSubscriptions = (subscription) => {
		setSelectedSubscriptions(subscription);
		setSubscriptionModalOpen(true);
	};

	const handleOpenSendNotification = async () => {
		setSendAlertModal(true);
	};

	const handleOpenPaymentModal = async () => {
		setPaymentModal(true);
	};


	const handleSyncStreaming = async (id, sync) => {
		setLoading("Sincronizando...");
		try {
			await api.get(`/webhooks/syncStreaming/${id}`);

			if (sync) {
				await handleSubscriptionsData();
				setLoading(false);
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}

	};

	const fetchSubscriptions = async () => {
		setLoading("Sincronizando...");
		try {
			if (modules.modulesStreaming === "enabled") {

				var promises = await subscriptionsData?.map(async item => {
					if (item?.msServerId) { await handleSyncStreaming(item.id) }
				})

				Promise.all(promises).then(async function () {
					await handleSubscriptionsData();
				})
			} else {
				await handleSubscriptionsData();

			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			toastError(err);
		}

	}

	const handleDeleteSubscriptions = async (subscriptionId) => {
		setLoading("Excluindo...");
		try {
			await api.delete(`/subscription/${subscriptionId}`);
			toast.success(i18n.t("global.toasts.deleted"));
			await handleSubscriptionsData();

		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		setDeletingSubscriptions(null);
	};

	const handleExtendSubscriptions = async (subscriptionId) => {
		setLoading("Renovando...");
		try {
			await api.get(`/webhooks/updateTVS/${subscriptionId}`);
			toast.success("Atualizado com sucesso");
			await fetchSubscriptions();
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		setDeletingSubscriptions(null);
	};

	return (
		<>
			<SubscriptionModal
				open={subscriptionModalOpen}
				onClose={handleCloseSubscriptionModal}
				aria-labelledby="form-dialog-title"
				subscriptionId={selectedSubscriptions && selectedSubscriptions.id}
				contact={selectedContact}
				modules={modules}
			></SubscriptionModal>
			<SendAlertNotification
				modalOpen={sendAlertModal}
				onClose={(e) => setSendAlertModal(false)}
				id={contact.id}
				openTicketonSendAlert={openTicketonSendAlert}
			/>
			<PaymentModal
				modalOpen={paymentModal}
				onClose={(e) => setPaymentModal(false)}
				contactId={contact.id}
				openTicketonSendAlert={openTicketonSendAlert}
				subscriptionsData={subscriptionsData}
				modules={modules}
				msServers={msServers}
			/>
			<Typography variant="subtitle1">
				<Typography variant="button" display="block" gutterBottom >
					{modules.modulesStreaming === "enabled" &&
						<IconButton
							size="small"
							onClick={() => fetchSubscriptions()}
							style={{ top: -38, left: 160 }}
						>
							<Tooltip title={i18n.t("global.buttons.actualize")} >
								<Sync />
							</Tooltip>
						</IconButton>
					}
					<IconButton
						size="small"
						onClick={() => handleOpenSubscriptionModal(contact)}
						style={{ top: -38, left: 160 }}
					>
						<Tooltip title={i18n.t("global.buttons.new")} >
							<AddCircle />
						</Tooltip>
					</IconButton>
					<IconButton
						size="small"
						onClick={() => handleOpenSendNotification(contact.id)}
						style={{ top: -38, left: 160 }}
					>
						<Tooltip title={i18n.t("subscription.sendAlert")} >
							<AddAlert color="secondary" />
						</Tooltip>
					</IconButton>
					<IconButton
						size="small"
						onClick={() => handleOpenPaymentModal(contact.id)}
						style={{ top: -38, left: 160 }}
					>
						<Tooltip title={i18n.t("subscription.addPayment")} >
							<MonetizationOn color="secondary" />
						</Tooltip>
					</IconButton>

				</Typography>
				<div style={{ marginTop: -30 }} >
					{loading && <><TableRowSkeleton columns={2} /> {loading}</>}
					{subscriptionsData &&
						subscriptionsData?.map(subs => (
							<div key={subs.id} className={classes.root}>
								<div style={{ marginTop: 10, marginBottom: 10, }}></div>
								<ConfirmationModal
									title={
										deletingSubscriptions &&
										`${i18n.t("global.deleteTitle")} ${deletingSubscriptions.serverName}?`
									}
									open={confirmModalOpen}
									onClose={setConfirmModalOpen}
									onConfirm={() => handleDeleteSubscriptions(deletingSubscriptions.subscriptionId)}
								>
									{i18n.t("global.deleteMessage")}
								</ConfirmationModal>

								<ConfirmationModal
									title={
										extendSubscriptions &&
										`Você vai estender 30 dias na conta ${extendSubscriptions.sUser} do servidor ${extendSubscriptions.serverName}?`
									}
									open={confirmExtendModalOpen}
									onClose={setConfirmExtendModalOpen}
									onConfirm={() => handleExtendSubscriptions(extendSubscriptions.subscriptionId)}
								>
									{i18n.t("global.deleteMessage")}
								</ConfirmationModal>

								<Paper variant="outlined" elevation={3} style={{ margin: 5, padding: 5, flex: 1, borderTop: "5px solid gray", backgroundColor: "transparent" }}>
									<Paper className={`${classes.title}`} variant="outlined" elevation={3} style={{ margin: 5, padding: 5, flex: 1 }}>
										{`#${subs.id}`} {modules.modulesStreaming === "enabled" && msServers &&
											msServerName(subs.msServerId)
										}

										<span color="secondary" >&nbsp;|&nbsp;</span>
										{modules.modulesStreaming === "enabled" &&
											<>
												{modules.painelTVS === "enabled" &&
													<IconButton
														size="small"
														onClick={(e) => {
															setConfirmExtendModalOpen(true);
															setExtendSubscriptions({
																sUser: subs.sUser,
																serverName: msServerName(subs.msServerId),
																subscriptionId: subs.id
															})
														}}
													>
														<span style={{ color: theme.palette.secondary.main, fontSize: "0.9em", fontWeight: 600 }} >+30</span>
													</IconButton>
												}

												<IconButton
													size="small"
													onClick={() => handleSyncStreaming(subs.id, true)}
												>
													<Tooltip title={i18n.t("global.buttons.actualize")} >
														<Sync />
													</Tooltip>
												</IconButton>
											</>
										}
										<IconButton
											size="small"
											onClick={() => handleEditSubscriptions(subs)}
										>
											<Tooltip title={i18n.t("global.buttons.edit")} >
												<Edit />
											</Tooltip>
										</IconButton>
										<IconButton
											size="small"
											onClick={(e) => {
												setConfirmModalOpen(true);
												setDeletingSubscriptions({
													serverName: msServerName(subs.msServerId),
													subscriptionId: subs.id
												})
											}}
										>
											<Tooltip title={i18n.t("global.buttons.delete")} >
												<DeleteOutline color="secondary" />
											</Tooltip>
										</IconButton>

									</Paper>
									<Paper variant="outlined" elevation={3} style={{ margin: 5, padding: 5, flex: 1, backgroundColor: "transparent" }}>
										{modules.modulesStreaming === "enabled" &&
											<>
												{subs.msServerId &&
													<>

														<div className={classes.infoTagMany}>
															<Person className={`${classes.infoButton} `} /><strong>{i18n.t("global.user")}:</strong>&nbsp;<span>{subs.sUser}</span>
														</div>
														<div className={classes.infoTagMany}>
															<LockOpen className={`${classes.infoButton} `} /><strong>{i18n.t("global.pass")}:</strong>&nbsp;<span>{subs.sPass}</span>
														</div>
													</>
												}
											</>
										}



										<div
											className={clsx(`${classes.infoTagMany}`, {
												[classes.infoTagGreen]: expireHours(subs.expiresAt) > 120,
												[classes.infoTagExpire72]: expireHours(subs.expiresAt) <= 120,
												[classes.infoTagExpire24]: expireHours(subs.expiresAt) <= 24,
												[classes.infoTagExpired]: expireHours(subs.expiresAt) <= 0,
											})}
										>
											<CalendarToday className={`${classes.infoButton}`} />
											<span><strong>{i18n.t("global.expiresAt")}:&nbsp;{subs.expiresAt ? moment(subs.expiresAt).format('DD/MM/YY HH:mm') : ""}</strong></span>
										</div>
										<div className={classes.infoTagMany}>
											<MonetizationOn className={`${classes.infoButton}`} /><strong>{i18n.t("global.value")}:</strong>&nbsp;<span> <PriceBRL value={subs.plan.price} /></span>&nbsp;({subs.plan.name})
										</div>
										<div style={{ textAlign: "center", flex: 1, fontSize: "0.7em", marginTop: 3 }}>{i18n.t("global.updatedAt")}: {subs.sLastUpdateSync ? moment(subs.sLastUpdateSync).format('DD/MM/YY HH:mm') : ""}</div>

										{subs?.description &&
											<div style={{ fontSize: "0.7em", justifyContent: "left", padding: 5, marginTop: 5, }}>
												<span style={{ display: "flex", }} >
													{i18n.t("global.description")}:
												</span>
												{subs?.description}
											</div>
										}

									</Paper>
									{modules.modulesStreaming === "enabled" && modules.launcherBox === "enabled" &&
										<ContactDrawerLauncherBoxCards subs={subs.id} />
									}

								</Paper>



							</div >

						))}
				</div>
			</Typography>
		</>
	);
};

export default ContactDrawerSubscription;
